import React, { createContext, useContext, useState } from 'react';
import { Attachment, IChatMessage } from '../libs/models/ChatMessage';
import { postMessageToParent } from '../libs/messaging';

interface AttachmentContextProps {
    activeAttachment: { attachment: Attachment | null; message: IChatMessage | 'closed' | null };
    setActiveAttachment: (attachment: Attachment | null, message: IChatMessage | 'closed' | null) => void;
    isAttachmentVisible: boolean;
    setAttachmentVisible: (visible: boolean) => void;
}

const AttachmentContext = createContext<AttachmentContextProps | undefined>(undefined);

export const AttachmentProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [activeAttachment, setActiveAttachmentState] = useState<{
        attachment: Attachment | null;
        message: IChatMessage | 'closed' | null;
    }>({ attachment: null, message: null });
    const [isAttachmentVisible, setAttachmentVisibleState] = useState(false);

    const setActiveAttachment = (attachment: Attachment | null, message: IChatMessage | 'closed' | null) => {
        setActiveAttachmentState({ attachment, message });
        setAttachmentVisibleState(attachment !== null);

        // Notify parent window about attachment state change
        postMessageToParent({
            event: 'attachmentStateChanged',
            data: {
                hasAttachment: attachment !== null,
            },
        });
    };

    const setAttachmentVisible = (visible: boolean) => {
        setAttachmentVisibleState(visible);
    };

    return (
        <AttachmentContext.Provider
            value={{ activeAttachment, setActiveAttachment, isAttachmentVisible, setAttachmentVisible }}
        >
            {children}
        </AttachmentContext.Provider>
    );
};

export const useAttachment = (): AttachmentContextProps => {
    const context = useContext(AttachmentContext);
    if (!context) {
        throw new Error('useAttachment must be used within an AttachmentProvider');
    }
    return context;
};
