import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { AppState } from '../../AppState';
import { AuthHelper, AuthType } from '../auth/AuthHelper';
import { useChat } from './useChat';
import { HostContext } from '../../embed-script/HostContext';

export const useAuthAndChatState = (
    appState: AppState,
    setAppState: React.Dispatch<React.SetStateAction<AppState>>,
    hostContext: HostContext,
) => {
    const auth = useAuth();
    const chat = useChat();

    useEffect(() => {
        if (
            (auth.isAuthenticated || AuthHelper.getAuthType() === AuthType.None) &&
            appState === AppState.SettingUserInfo
        ) {
            setAppState(AppState.LoadingChats);
            console.log('Loading chats');
            void Promise.all([
                chat.loadChats(hostContext).then((succeeded) => {
                    if (succeeded) {
                        setAppState(AppState.Chat);
                    } else {
                        setAppState(AppState.SigningOut);
                    }
                }),
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.isAuthenticated, appState]);
};
