import { useState } from 'react';
import { Attachment } from '../../libs/models/ChatMessage';
import { makeStyles } from '@fluentui/react-components';
import { Info16Regular, ChevronDown16Regular } from '@fluentui/react-icons';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@fluentui/react-components';

interface CalculationGuideButtonProps {
    attachment: Attachment;
    className?: string;
}

const useStyles = makeStyles({
    container: {
        width: '100%',
        marginBottom: '0.25rem',
        position: 'relative',
    },
    buttonBase: {
        width: '100%',
        minHeight: '2rem',
        padding: '0.5rem',
        border: '1px solid #e5e7eb',
        borderRadius: '0.375rem',
        backgroundColor: 'white',
        ':hover': {
            backgroundColor: '#f9fafb',
        },
    },
    buttonOpen: {
        backgroundColor: '#f3f4f6',
        border: '1px solid #d1d5db',
        boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.1)',
    },
    buttonClosed: {
        backgroundColor: 'white',
        ':hover': {
            backgroundColor: '#f9fafb',
        },
        border: '1px solid #e5e7eb',
    },
    buttonContent: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        width: '100%',
        '@media (max-width: 768px)': {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    titleSection: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        flexShrink: 0,
    },
    icon: {
        height: '1rem',
        width: '1rem',
        color: '#3b82f6',
    },
    title: {
        fontSize: '0.875rem',
        fontWeight: 500,
    },
    summary: {
        fontSize: '0.75rem',
        fontStyle: 'italic',
        color: '#6b7280',
        flex: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        paddingRight: '9rem',
        '@media (max-width: 768px)': {
            width: '100%',
            paddingLeft: 0,
            paddingRight: '0.5rem',
            whiteSpace: 'normal',
            marginBottom: '0.5rem',
        },
    },
    clickPrompt: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.25rem',
        fontSize: '0.625rem',
        color: '#9ca3af',
        marginLeft: 'auto',
        flexShrink: 0,
        width: '8.5rem',
        '@media (max-width: 768px)': {
            width: '100%',
            justifyContent: 'flex-end',
            paddingLeft: 0,
            paddingRight: '0.5rem',
        },
    },
    chevron: {
        height: '0.75rem',
        width: '0.75rem',
    },
    overlay: {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 39,
    },
    modal: {
        position: 'absolute',
        top: '100%',
        marginTop: '0.25rem',
        left: 0,
        right: 0,
        backgroundColor: 'white',
        borderRadius: '0.375rem',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
        border: '1px solid #e5e7eb',
        maxWidth: '400px',
        zIndex: 40,
    },
    modalContent: {
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    },
    modalTitle: {
        fontSize: '0.875rem',
        fontWeight: 500,
        color: '#111827',
    },
    calculationsContainer: {
        marginTop: '0.5rem',
        gap: '0.75rem',
    },
    sectionContainer: {
        gap: '0.5rem',
    },
    sectionTitle: {
        fontSize: '0.875rem',
        fontWeight: 500,
        color: '#374151',
    },
    actionsList: {
        listStyleType: 'disc',
        paddingLeft: '1rem',
        gap: '0.25rem',
    },
    actionItem: {
        fontSize: '0.75rem',
        color: '#4b5563',
    },
    prerequisitesSection: {
        paddingTop: '0.5rem',
        borderTop: '1px solid #f3f4f6',
    },
    prerequisitesTitle: {
        fontSize: '0.75rem',
        fontWeight: 500,
        color: '#374151',
        marginBottom: '0.25rem',
    },
    prerequisitesList: {
        listStyleType: 'disc',
        paddingLeft: '1rem',
        gap: '0.25rem',
    },
    note: {
        fontSize: '0.75rem',
        color: '#4b5563',
        fontStyle: 'italic',
    },
    skeletonContainer: {
        width: '100%',
        marginBottom: '0.25rem',
    },
    skeletonContent: {
        padding: '0.5rem',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        border: '1px solid #e5e7eb',
        borderRadius: '0.375rem',
        backgroundColor: 'white',
        width: '100%',
        '@media (max-width: 768px)': {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    skeletonTitleSection: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        flexShrink: 0,
    },
    skeletonSummary: {
        flex: 1,
        paddingRight: '9rem',
        '@media (max-width: 768px)': {
            width: '100%',
            paddingRight: '0.5rem',
        },
    },
    skeletonClickPrompt: {
        marginLeft: 'auto',
        width: '8.5rem',
        flexShrink: 0,
        '@media (max-width: 768px)': {
            width: '100%',
            justifyContent: 'flex-end',
        },
    },
});

export default function CalculationGuideButton({ attachment, className = '' }: CalculationGuideButtonProps) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation();

    if (attachment.creatingExplanation && !attachment.explanation) {
        return (
            <div className={`${classes.container} ${className}`}>
                <button className={`${classes.buttonBase} ${classes.buttonClosed}`}>
                    <div className={classes.buttonContent}>
                        <div className={classes.titleSection}>
                            <Info16Regular className={classes.icon} />
                            <Skeleton
                                style={{
                                    width: '160px',
                                    height: '16px',
                                    borderRadius: '4px',
                                    backgroundColor: '#e5e7eb',
                                }}
                            />
                        </div>
                        <span className={classes.summary} style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '4px', color: '#6b7280' }}>—</span>
                            <Skeleton
                                style={{
                                    flex: 1,
                                    height: '12px',
                                    borderRadius: '4px',
                                    backgroundColor: '#e5e7eb',
                                }}
                            />
                        </span>
                        <div className={classes.clickPrompt}>
                            <ChevronDown16Regular className={classes.chevron} />
                            <Skeleton
                                style={{
                                    width: '80px',
                                    height: '10px',
                                    borderRadius: '4px',
                                    backgroundColor: '#e5e7eb',
                                }}
                            />
                        </div>
                    </div>
                </button>
            </div>
        );
    }

    if (!attachment.explanation) {
        return null;
    }

    const guide = attachment.explanation;

    return (
        <div className={`${classes.container} ${className}`}>
            <button
                onClick={() => {
                    setIsModalOpen(!isModalOpen);
                }}
                className={`${classes.buttonBase} ${isModalOpen ? classes.buttonOpen : classes.buttonClosed}`}
            >
                <div className={classes.buttonContent}>
                    <div className={classes.titleSection}>
                        <Info16Regular className={classes.icon} />
                        <span className={classes.title}>{t('calculationGuide.understandingResults')}</span>
                    </div>
                    <span className={classes.summary}>— {guide.summary}</span>
                    <div className={classes.clickPrompt}>
                        <ChevronDown16Regular className={classes.chevron} />
                        <span>{t('calculationGuide.clickForDetails')}</span>
                    </div>
                </div>
            </button>
            {isModalOpen && (
                <>
                    <div
                        className={classes.overlay}
                        onClick={() => {
                            setIsModalOpen(false);
                        }}
                    />
                    <div className={classes.modal}>
                        <div className={classes.modalContent}>
                            <div>
                                <h3 className={classes.modalTitle}>{guide.title}</h3>
                                <div className={classes.calculationsContainer}>
                                    {guide.calculations.map((section, idx) => (
                                        <div key={idx} className={classes.sectionContainer}>
                                            <h4 className={classes.sectionTitle}>{section.title}</h4>
                                            <ul className={classes.actionsList}>
                                                {section.actions.map((action, actionIdx) => (
                                                    <li key={actionIdx} className={classes.actionItem}>
                                                        {action}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {guide.note && (
                                <div className={classes.note}>
                                    {t('calculationGuide.note')}: {guide.note}
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
