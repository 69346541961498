import { useState, useEffect, useMemo } from 'react';

export type ChartComponent = React.ComponentType<{
    data: any;
    options: any;
    type: string;
}>;

export const useChart = (): ChartComponent | null => {
    const [chartModules, setChartModules] = useState<ChartComponent | null>(null);

    useEffect(() => {
        const loadChart = async (): Promise<void> => {
            try {
                const chartJsPromise = import('chart.js');
                const reactChartJs2Promise = import('react-chartjs-2');
                const chartJsAdapterMomentPromise = import('chartjs-adapter-moment');
                const [
                    {
                        Chart: ChartJS,
                        ArcElement,
                        Tooltip,
                        Legend,
                        BarElement,
                        CategoryScale,
                        LineElement,
                        LinearScale,
                        PointElement,
                        TimeScale,
                        Title,
                    },
                    { Chart },
                ] = await Promise.all([chartJsPromise, reactChartJs2Promise, chartJsAdapterMomentPromise]);

                ChartJS.register(
                    ArcElement,
                    BarElement,
                    CategoryScale,
                    Legend,
                    LinearScale,
                    LineElement,
                    PointElement,
                    TimeScale,
                    Title,
                    Tooltip,
                );

                setChartModules(Chart as unknown as ChartComponent);
            } catch (error) {
                console.error('Error loading Chart:', error instanceof Error ? error.message : 'Unknown error');
            }
        };

        void loadChart();
    }, []);

    return useMemo(() => chartModules, [chartModules]);
};
