import { DataSetService } from '../services/DataSetService';
import { useAuth } from 'react-oidc-context';
import { useMemo } from 'react';

export const useDataSet = () => {
    const auth = useAuth();
    const token = auth.user?.access_token;

    const datasetService = useMemo(() => new DataSetService(token ?? ''), [token]);

    return useMemo(
        () => ({
            getTableData: (...args: Parameters<DataSetService['getTableData']>) => datasetService.getTableData(...args),
            getDataSetById: (id: string) => datasetService.getDataSetById(id),
        }),
        [datasetService],
    );
};
