import { FluentProvider, Subtitle1, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

import { UserSettingsMenu } from './components/header/UserSettingsMenu';
import { WelcomeDialog } from './components/shared/WelcomeDialog';
import { BackendProbe, ChatView, Error, Loading, Login } from './components/views';
import { AuthHelper, AuthType } from './libs/auth/AuthHelper';
import { useAppDispatch, useAppSelector } from './redux/app/hooks';
import { RootState } from './redux/app/store';
import { setShowCsat } from './redux/features/app/appSlice';
import { semanticKernelLightTheme } from './styles';
import '/node_modules/flag-icons/css/flag-icons.min.css';

import { AppState } from './AppState';
import logo from './assets/Visma_spcs_logo_vanilla.svg';
import './i18n';
import { HostContextSelector } from './components/header/HostContextSelector';
import { useMessageHandler } from './libs/hooks/useMessageHandler';
import SatisfactionRating from './components/shared/SatisfactionRating';
import { useEmbeddedMode } from './libs/hooks/useEmbeddedMode';
import { useAuthAndChatState } from './libs/hooks/useAuthAndChatState';
import { useInitialServiceInfo } from './libs/hooks/useInitialServiceInfo';
import { useHostContextInitialization } from './libs/hooks/useHostContextInitialization';

export const useClasses = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        overflow: 'hidden',
    },
    header: {
        alignItems: 'center',
        backgroundColor: '#F56262',
        color: tokens.colorNeutralForegroundOnBrand,
        display: 'flex',
        '& h1': {
            paddingLeft: tokens.spacingHorizontalM,
            display: 'flex',
            ...shorthands.flex(1),
        },
        '& img': {
            display: 'flex',
            width: '150px',
        },
        height: '48px',
        justifyContent: 'space-between',
        width: '100%',
    },
    persona: {
        marginRight: tokens.spacingHorizontalXXL,
    },
    cornerItems: {
        display: 'flex',
        ...shorthands.gap(tokens.spacingHorizontalS),
    },
});

const OidcNavigationGuard: FC<{ children: React.ReactNode }> = ({ children }) => {
    const auth = useAuth();
    const { t } = useTranslation();

    // Handle OIDC navigation states
    switch (auth.activeNavigator) {
        case 'signinSilent':
            console.log('signinSilent mode on page');
            return <Loading text={t('app.signingIn')} />;
        case 'signoutRedirect':
            console.log('signoutRedirect mode on page');
            return <Loading text={t('app.signingOut')} />;
    }

    if (auth.isLoading) {
        return <Loading text={t('app.loading')} />;
    }

    if (auth.error) {
        return <Error text={auth.error.message} />;
    }

    return <>{children}</>;
};

const App: FC = () => {
    const classes = useClasses();
    const auth = useAuth();

    const [appState, setAppState] = React.useState(AppState.SettingUserInfo);

    const { hostContext, isInitialized: hostContextInitialized } = useAppSelector(
        (state: RootState) => state.hostContext,
    );

    const { embeddedMode } = useAppSelector((state: RootState) => state.app);
    useHostContextInitialization(hostContextInitialized, embeddedMode);
    const { t } = useTranslation();

    // Set the page title
    const title = t('app.title');
    useEffect(() => {
        document.title = title;
    }, [title]);

    useEmbeddedMode();
    useAuthAndChatState(appState, setAppState, hostContext);
    useInitialServiceInfo(hostContext);

    const showHeader = !embeddedMode;

    const authType = AuthHelper.getAuthType();

    return (
        <FluentProvider className="app-container" theme={semanticKernelLightTheme}>
            {authType == AuthType.OIDC && (
                <>
                    {!auth.isAuthenticated ? (
                        <div className={classes.container}>
                            <div className={classes.header}>
                                <Subtitle1 as="h1">{t('app.subTitle')}</Subtitle1>
                            </div>
                            {appState === AppState.SigningOut && <Loading text={t('app.signingOut')} />}
                            {appState !== AppState.SigningOut && <Login />}
                        </div>
                    ) : (
                        <Chat classes={classes} appState={appState} setAppState={setAppState} showHeader={showHeader} />
                    )}
                </>
            )}
            {authType == AuthType.None && (
                <Chat classes={classes} appState={appState} setAppState={setAppState} showHeader={showHeader} />
            )}
        </FluentProvider>
    );
};

const Chat = ({
    classes,
    appState,
    setAppState,
    showHeader,
}: {
    classes: ReturnType<typeof useClasses>;
    appState: AppState;
    setAppState: React.Dispatch<React.SetStateAction<AppState>>;
    showHeader: boolean;
}) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const showCsat = useAppSelector((state: RootState) => state.app.showCsat);

    useMessageHandler({
        setAppState,
        i18n,
        setShowCsat: (show: boolean) => dispatch(setShowCsat(show)),
    });

    return (
        <div className={classes.container}>
            <div className={classes.header} style={{ display: showHeader ? '' : 'none' }}>
                <img src={logo} />
                <Subtitle1 as="h1">{t('app.title')}</Subtitle1>
                {showHeader && <HostContextSelector />}
                <div className={classes.cornerItems}>
                    <div className={classes.cornerItems}>
                        <UserSettingsMenu
                            setLoadingState={() => {
                                setAppState(AppState.SigningOut);
                            }}
                            setAppState={setAppState}
                        />
                    </div>
                </div>
            </div>
            {appState === AppState.ProbeForBackend && (
                <BackendProbe
                    uri={process.env.REACT_APP_BACKEND_URI as string}
                    onBackendFound={() => {
                        if (AuthHelper.getAuthType() == AuthType.None) {
                            setAppState(AppState.LoadingChats);
                        } else {
                            setAppState(AppState.SettingUserInfo);
                        }
                    }}
                />
            )}
            {appState === AppState.SettingUserInfo && <Loading text={t('app.loadingUserInfo')} />}
            {appState === AppState.ErrorLoadingUserInfo && <Error text={t('error.general')} />}
            {appState === AppState.LoadingChats && <Loading text={t('app.loadingChats')} />}
            {appState === AppState.Chat && (
                <>
                    {showCsat ? (
                        <SatisfactionRating
                            isOpen={showCsat}
                            onClose={() => {
                                dispatch(setShowCsat(false));
                                window.parent.postMessage({ event: 'hideCsat' }, '*');
                            }}
                        />
                    ) : (
                        <ChatView setAppState={setAppState} />
                    )}
                </>
            )}
            <WelcomeDialog />
        </div>
    );
};

const AppWithAuth: FC = () => (
    <OidcNavigationGuard>
        <App />
    </OidcNavigationGuard>
);

export default AppWithAuth;
