import { useEffect } from 'react';
import { useAppDispatch } from '../../redux/app/hooks';
import { setHostContext } from '../../redux/features/hostContext/hostContextSlice';
import { HostContext } from '../../embed-script/HostContext';

export const useHostContextInitialization = (hostContextInitialized: boolean, embeddedMode: boolean) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const setHostContextState = (newHostContext: HostContext) => {
            console.log('setHostContextState', newHostContext);
            dispatch(setHostContext(newHostContext));
        };

        if (hostContextInitialized) {
            return;
        }

        const hostContextJsonFromUrl = new URLSearchParams(window.location.search).get('hostContext');
        let hostContextFromUrl: HostContext | undefined;
        if (hostContextJsonFromUrl) {
            const url = new URL(window.location.href);
            url.searchParams.delete('hostContext');
            window.history.replaceState({}, '', url.toString());
            try {
                hostContextFromUrl = JSON.parse(hostContextJsonFromUrl) as HostContext;
                console.log('hostContextFromUrl', hostContextFromUrl);
            } catch (error) {
                console.error('Failed to parse hostContextJson', error);
            }
        }

        // Use the url-provided hostContext if it's provided, otherwise use the one from localStorage
        // The one in the url is provided when embedding the chat and used to inject the initial hostContext
        const storedHostContextString = localStorage.getItem('hostContext');

        const defaultHostContext: HostContext =
            hostContextFromUrl ??
            (storedHostContextString && !embeddedMode ? (JSON.parse(storedHostContextString) as HostContext) : {});

        console.log('defaultHostContext', defaultHostContext);

        const defaultHostContextEmpty = Object.keys(defaultHostContext).length === 0;

        if (!defaultHostContextEmpty) {
            console.log('defaultHostContext setting', Object.keys(defaultHostContext).length, defaultHostContext);
            setHostContextState(defaultHostContext);
        } else {
            console.log('Not setting hostContext as no was provided here or in storage');
        }
    }, [hostContextInitialized, dispatch, embeddedMode]);
};
