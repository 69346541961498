import { useState, useEffect } from 'react';
import { ChartAttachment } from '../models/ChatMessage';
import { useDataSet } from './useDataSet';

interface ProcessedData {
    data: unknown;
    options: unknown;
    type: string;
}

type ChartMappingFunction = (config: Record<string, unknown>, data: unknown) => void;

export const useChartData = (attachment: ChartAttachment): ProcessedData | null => {
    const [processedData, setProcessedData] = useState<ProcessedData | null>(null);
    const { getDataSetById } = useDataSet();

    useEffect(() => {
        const processData = async () => {
            try {
                const dataset = await getDataSetById(attachment.datasetReferenceId);
                const mappingFunctionString = attachment.mappingFunction;

                // Strip leading comments and whitespace to find the start of the actual function
                const cleanedFunctionString = mappingFunctionString
                    .split('\n')
                    .filter((line) => !line.trim().startsWith('//'))
                    .join('\n')
                    .trim();

                // Wrap the function string to return the actual function
                const wrappedFunctionString = `return ${cleanedFunctionString}`;

                // Create and immediately execute the function factory
                // eslint-disable-next-line @typescript-eslint/no-implied-eval
                const evaluatedFunction = new Function(wrappedFunctionString)() as unknown;
                if (typeof evaluatedFunction !== 'function') {
                    throw new Error('Mapping function is not a valid function');
                }

                const mappingFunction = evaluatedFunction as ChartMappingFunction;

                // Add type assertion and validation for the config
                let configObject: Record<string, unknown>;
                if (typeof attachment.config === 'string') {
                    const parsedConfig = eval(`(${attachment.config})`) as unknown;
                    if (typeof parsedConfig !== 'object' || parsedConfig === null) {
                        throw new Error('Invalid config structure');
                    }
                    configObject = parsedConfig as Record<string, unknown>;
                } else {
                    configObject = attachment.config;
                }

                if (!configObject.data) {
                    configObject.data = {};
                }

                mappingFunction(configObject, dataset);

                setProcessedData({
                    options: configObject.options,
                    data: configObject.data,
                    type: configObject.type as string,
                });
            } catch (error) {
                console.error('Error fetching dataset:', error);
            }
        };

        void processData();
    }, [attachment, getDataSetById]);

    return processedData;
};
