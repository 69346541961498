import { AjaxData, AjaxResponse, InternalSettings } from 'datatables.net-dt';
import { BackendServiceUrl, BaseService } from './BaseService';

// Define an interface for AdditionalParameters
type AdditionalParameters = Record<string, any>;

// Extend AjaxData to include AdditionalParameters
interface ExtendedAjaxData extends AjaxData {
    AdditionalParameters: AdditionalParameters;
}

// Define a type for the dataset response
export interface DataSetResponse {
    headers?: string[];
    rows: object[][];
    numRows: number;
    columnTypes?: string[];
    columns?: Column[];
}

export interface Column {
    name: string;
    label?: string;
    dataType?: string;
    enumValues?: string[];
}

export class DataSetService extends BaseService {
    private lastRequest: { params: ExtendedAjaxData; result: Promise<AjaxResponse> } | null = null;
    private lastDataSetRequest: { id: string; result: Promise<DataSetResponse> } | null = null;

    constructor(accessToken: string, baseServiceUrl: string = BackendServiceUrl) {
        super(accessToken, baseServiceUrl);
    }

    public async getTableData(
        data: AjaxData,
        datasetReferenceId: string,
        callback: (data: any) => void,
        _settings: InternalSettings,
    ): Promise<void> {
        if (process.env.NODE_ENV === 'development') {
            console.log('Getting table data for dataset', datasetReferenceId, data);
        }

        const dataWithAdditionalParams: ExtendedAjaxData = {
            ...data,
            AdditionalParameters: {
                datasetReferenceId: datasetReferenceId,
            },
        };

        // Check if this is the same request as last time
        try {
            if (
                this.lastRequest &&
                JSON.stringify(this.lastRequest.params) === JSON.stringify(dataWithAdditionalParams)
            ) {
                console.log('Using cached request');
                const result = await this.lastRequest.result;
                callback(result);
                return;
            }
        } catch (error) {
            console.warn('Failed to compare requests:', error);
        }

        // Create new request and cache it
        const requestPromise = this.getResponseAsync<AjaxResponse>({
            commandPath: 'dataset/datatable',
            method: 'POST',
            body: dataWithAdditionalParams,
        });

        this.lastRequest = {
            params: dataWithAdditionalParams,
            result: requestPromise,
        };

        const result = await requestPromise;

        if (process.env.NODE_ENV === 'development') {
            console.log('Dataset Result', result);
        }

        callback(result);
    }

    public async getDataSetById(id: string): Promise<DataSetResponse> {
        console.log('Getting dataset by id', id);
        if (!id) {
            return Promise.reject(new Error('Dataset reference id is invalid.'));
        }

        // Check if this is the same request as last time
        if (this.lastDataSetRequest && this.lastDataSetRequest.id === id) {
            console.log('Using cached dataset request');
            return this.lastDataSetRequest.result;
        }

        // Create new request and cache it
        const requestPromise = this.getResponseAsync<DataSetResponse>({
            commandPath: `dataset/${id}`,
            method: 'GET',
        });

        this.lastDataSetRequest = {
            id,
            result: requestPromise,
        };

        return requestPromise;
    }
}
