import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GetResponseOptions, useChat } from '../../libs/hooks/useChat';
import { AlertType } from '../../libs/models/AlertType';
import { ChatMessageType } from '../../libs/models/ChatMessage';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { addAlert } from '../../redux/features/app/appSlice';
import { editConversationInput, updateBotResponseStatus } from '../../redux/features/conversations/conversationsSlice';
import { SharedStyles } from '../../styles';
import { ChatInput } from './ChatInput';
import { ChatHistory } from './chat-history/ChatHistory';

const useClasses = makeStyles({
    root: {
        //        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    scroll: {
        ...shorthands.margin(tokens.spacingVerticalXS),
        ...SharedStyles.scroll,
        // overflowY: 'auto', // Ensure the container is scrollable
        // flex: 1, // Allow it to take available space
        // maxHeight: 'calc(100vh - <header-height> - <input-height>)', // Adjust based on header and input heights
    },
    history: {
        ...shorthands.padding(tokens.spacingVerticalM),
        marginLeft: '40px',
        paddingRight: '40px',
        display: 'flex',
        justifyContent: 'center',
    },
    historyEmbedded: {
        ...shorthands.padding(tokens.spacingVerticalXXL),
        marginLeft: '0px',
        display: 'flex',
        justifyContent: 'center',
    },
    input: {
        ...shorthands.padding(tokens.spacingVerticalM),
    },
});

export const ChatRoom: React.FC = () => {
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const { embeddedMode } = useAppSelector((state: RootState) => state.app);
    const hostContext = useAppSelector((state: RootState) => state.hostContext.hostContext);
    const statuses = useAppSelector(
        (state: RootState) => state.conversations.conversations[selectedId].botResponseStatusHistory,
    );

    const messages = conversations[selectedId].messages;
    const classes = useClasses();

    const scrollViewTargetRef = React.useRef<HTMLDivElement>(null);
    const [shouldAutoScroll, setShouldAutoScroll] = React.useState(true);

    const { t } = useTranslation();

    const chat = useChat();
    const dispatch = useAppDispatch();
    const handleQuestionButtonClick = (text: string) => {
        dispatch(editConversationInput({ id: selectedId, newInput: text }));
    };

    React.useEffect(() => {
        if (!shouldAutoScroll) return;
        scrollViewTargetRef.current?.scrollTo(0, scrollViewTargetRef.current.scrollHeight);
    }, [messages, shouldAutoScroll, statuses]);

    React.useEffect(() => {
        const onScroll = () => {
            if (!scrollViewTargetRef.current) return;
            const { scrollTop, scrollHeight, clientHeight } = scrollViewTargetRef.current;
            const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10;
            setShouldAutoScroll(isAtBottom);
        };

        if (!scrollViewTargetRef.current) return;

        const currentScrollViewTarget = scrollViewTargetRef.current;

        currentScrollViewTarget.addEventListener('scroll', onScroll);
        return () => {
            currentScrollViewTarget.removeEventListener('scroll', onScroll);
        };
    }, []);

    const onSubmit = async (options: GetResponseOptions) => {
        options.hostContext = hostContext;
        await chat.getResponse(options);

        setShouldAutoScroll(true);
    };

    const handleSubmit = (value: string, messageType: ChatMessageType = ChatMessageType.Message) => {
        if (value.trim() === '') {
            return; // only submit if value is not empty
        }

        dispatch(editConversationInput({ id: selectedId, newInput: '' }));
        dispatch(updateBotResponseStatus({ chatId: selectedId, status: 'callingKernel' }));
        onSubmit({ value, messageType, chatId: selectedId }).catch((error: unknown) => {
            const message = t('error.submitChat', { message: (error as Error).message });

            dispatch(
                addAlert({
                    type: AlertType.Error,
                    message,
                }),
            );
        });
    };

    return (
        <div className={classes.root}>
            <div ref={scrollViewTargetRef} className={classes.scroll}>
                <div className={embeddedMode ? classes.historyEmbedded : classes.history}>
                    <ChatHistory
                        onQuerySubmit={handleSubmit}
                        statuses={conversations[selectedId].botResponseStatusHistory}
                    />
                </div>
            </div>
            <div className={classes.input}>
                <ChatInput handleSubmit={handleSubmit} onQuestionButtonClick={handleQuestionButtonClick} />
            </div>
        </div>
    );
};
