export type MessageEventType = 'attachmentStateChanged' | 'hashchange' | 'aiassitantcontextupdate';

interface BasePostMessage {
    event: MessageEventType;
    data: unknown;
}

interface AttachmentStateMessage extends BasePostMessage {
    event: 'attachmentStateChanged';
    data: {
        hasAttachment: boolean;
    };
}

interface HashChangeMessage extends BasePostMessage {
    event: 'hashchange';
    data: {
        hash: string;
        title?: string;
    };
}

interface ContextUpdateMessage extends BasePostMessage {
    event: 'aiassitantcontextupdate';
    data: Record<string, unknown>;
}

export type PostMessage = AttachmentStateMessage | HashChangeMessage | ContextUpdateMessage;

export const postMessageToParent = (message: PostMessage): void => {
    if (window.parent !== window) {
        window.parent.postMessage(message, '*');
    }
};
