import { useEffect } from 'react';
import { useAppDispatch } from '../../redux/app/hooks';
import { useChat } from './useChat';
import { HostContext } from '../../embed-script/HostContext';
import { setServiceInfo } from '../../redux/features/app/appSlice';
import { useAuth } from 'react-oidc-context';

export const useInitialServiceInfo = (hostContext: HostContext) => {
    const dispatch = useAppDispatch();
    const chat = useChat();
    const auth = useAuth();

    const fetchAndSetServiceInfo = async () => {
        console.log('Fetching service info from SettingUserInfo');
        const serviceInfo = await chat.getServiceInfo(hostContext);
        if (serviceInfo) {
            console.log('Received service info:', serviceInfo);
            dispatch(setServiceInfo(serviceInfo));
            const serializableFeatureFlags = Object.fromEntries(
                Object.entries(serviceInfo.featureFlags).map(([key, value]) => [key, !!value]),
            );
            console.log('Sending featureflagsreceived message with feature flags:', serializableFeatureFlags);
            window.parent.postMessage(
                {
                    event: 'featureflagsreceived',
                    data: { featureFlags: serializableFeatureFlags },
                },
                '*',
            );
        }
    };

    useEffect(() => {
        if (auth.isAuthenticated) {
            void fetchAndSetServiceInfo();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.isAuthenticated, hostContext]);
};
