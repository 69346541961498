import React from 'react';
import { AuthContext, AuthContextProps } from 'react-oidc-context';

export const noneAuthContext: AuthContextProps = {
    // AuthState properties
    user: null,
    isAuthenticated: false,
    isLoading: false,
    activeNavigator: undefined,
    error: undefined,

    // Settings and events from existing code
    settings: {
        authority: '',
        client_id: '',
        redirect_uri: '',
    },
    signinSilent: async () => {
        return Promise.resolve(null);
    },
    signinRedirect: async () => {
        return Promise.resolve();
    },
    signoutRedirect: async () => {
        window.location.reload();
        return Promise.resolve();
    },
    // @ts-expect-error ts(2740) - events is not defined here in this none auth context
    events: {},
};

// Create a provider component that supplies your context
export const NoneAuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return <AuthContext.Provider value={noneAuthContext}>{children}</AuthContext.Provider>;
};
