import 'datatables.net-buttons-dt';
import 'datatables.net-buttons/js/buttons.html5';
import DT, { AjaxData, AjaxDataColumn, InternalSettings } from 'datatables.net-dt';
import DataTable from 'datatables.net-react';
import 'datatables.net-select-dt';
import jszip from 'jszip';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { FC, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@fluentui/react-components';
import '../../../node_modules/datatables.net-dt/css/dataTables.dataTables.css';
import '../../../src/datatable.css';
import { TableAttachment } from '../../libs/models/ChatMessage';
import { sanitizeFilename } from './sanitizeFilename';
import { useDataSet } from '../../libs/hooks/useDataSet';
import { addAlert } from '../../redux/features/app/appSlice';
import { AlertType } from '../../libs/models/AlertType';
import { Column } from '../../libs/services/DataSetService';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

DataTable.use(DT);
DT.Buttons.jszip(jszip);
DT.Buttons.pdfMake(pdfMake);

interface AiaDataTableProps {
    attachment: TableAttachment;
}

interface ExtendedAjaxDataColumn extends AjaxDataColumn {
    title: string;
    type?: string;
}

const useStyles = makeStyles({
    tableWrapper: {
        height: '100%',
        maxHeight: '80vh',
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        zIndex: 1,
        overflow: 'hidden',
        contain: 'strict',
        isolation: 'isolate',
    },
    dataTable: {
        flex: 1,
        overflow: 'auto',
    },
});

const AiaDataTable: FC<AiaDataTableProps> = ({ attachment }) => {
    const classes = useStyles();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [prevDatasetReferenceId, setPrevDatasetReferenceId] = useState<string | null>(null);
    const [tableKey, setTableKey] = useState<number>(0);
    const [columns, setColumns] = useState<ExtendedAjaxDataColumn[]>([]);
    const dispatch = useDispatch();

    const getTableHeight = useMemo(() => {
        if (!wrapperRef.current) return '600px';
        const height = wrapperRef.current.clientHeight;
        const adjustedHeight = Math.max(height - 50, 200);
        console.log('Table height:', adjustedHeight);
        return adjustedHeight.toString() + 'px';
    }, [wrapperRef]);

    const { t } = useTranslation();
    const { getTableData, getDataSetById } = useDataSet();

    const fetchColumns = useCallback(async () => {
        const datasetReferenceId = attachment.datasetReferenceId;
        if (datasetReferenceId) {
            try {
                const dataSetResponse = await getDataSetById(datasetReferenceId);
                const columnsToShow = attachment.columnsToShow.split(',').map((s) => s.trim());
                const columns: ExtendedAjaxDataColumn[] = columnsToShow.map((column: string) => {
                    const columnInfo = dataSetResponse.columns?.find((col: Column) => col.name === column);
                    return {
                        name: column,
                        data: column,
                        orderable: true,
                        searchable: true,
                        search: { value: '', regex: false },
                        title: columnInfo?.label ?? column, // Use Label if exists, otherwise use column name
                        type: columnInfo && 'type' in columnInfo && columnInfo.type === 'number' ? 'num' : undefined, // Set type to 'num' for numeric columns
                    };
                });
                setColumns(columns);
            } catch (_error: unknown) {
                dispatch(
                    addAlert({
                        message: t('alerts.tableDataError'),
                        type: AlertType.Error,
                    }),
                );
            }
        }
    }, [attachment, getDataSetById, t, dispatch]);

    const ajaxCallback = useCallback(
        async (data: AjaxData, callback: (data: unknown) => void, settings: InternalSettings): Promise<void> => {
            const datasetReferenceId = attachment.datasetReferenceId;
            if (datasetReferenceId) {
                try {
                    await getTableData(
                        data,
                        datasetReferenceId,
                        (result) => {
                            callback(result);
                        },
                        settings,
                    );
                } catch (error: unknown) {
                    dispatch(
                        addAlert({
                            message: t('alerts.tableDataError'),
                            type: AlertType.Error,
                        }),
                    );
                    console.error('Error fetching table data:', error);
                }
            }
        },
        [attachment, getTableData, t, dispatch],
    );

    const dataTableLanguage = {
        processing: t('datatable.processing'),
        search: t('datatable.search'),
        lengthMenu: t('datatable.lengthMenu'),
        info: t('datatable.info'),
        infoEmpty: t('datatable.infoEmpty'),
        infoFiltered: t('datatable.infoFiltered'),
        infoPostFix: t('datatable.infoPostFix'),
        loadingRecords: t('datatable.loadingRecords'),
        zeroRecords: t('datatable.zeroRecords'),
        emptyTable: t('datatable.emptyTable'),
        paginate: {
            first: t('datatable.paginate.first'),
            previous: t('datatable.paginate.previous'),
            next: t('datatable.paginate.next'),
            last: t('datatable.paginate.last'),
        },
        aria: {
            sortAscending: t('datatable.aria.sortAscending'),
            sortDescending: t('datatable.aria.sortDescending'),
        },
    };

    useEffect(() => {
        const fetchColumnsAsync = async () => {
            if (attachment.datasetReferenceId !== prevDatasetReferenceId) {
                setPrevDatasetReferenceId(attachment.datasetReferenceId);
                setTableKey((prevKey) => prevKey + 1); // Update the key to force re-render
                setColumns([]); // Reset columns before fetching new ones
                await fetchColumns(); // Fetch columns when datasetReferenceId changes
            }
        };

        void fetchColumnsAsync();
    }, [attachment.datasetReferenceId, prevDatasetReferenceId, fetchColumns]);

    if (columns.length > 0) {
        const sanitizedFilename = sanitizeFilename(attachment.name);
        return (
            <div className={classes.tableWrapper} ref={wrapperRef}>
                <DataTable
                    key={tableKey} // Use the key to force re-render
                    className="display dataTable"
                    ajax={(data: unknown, callback: (data: unknown) => void, settings: unknown): void => {
                        void ajaxCallback(data as AjaxData, callback, settings as InternalSettings);
                    }}
                    options={{
                        serverSide: true,
                        columns,
                        language: dataTableLanguage,
                        scrollX: true,
                        scrollY: getTableHeight,
                        scrollCollapse: true,
                        autoWidth: true,
                        layout: {
                            top1Start: 'buttons',
                        },
                        select: true,
                        buttons: [
                            'copy',
                            {
                                extend: 'csvHtml5',
                                text: 'CSV',
                                filename: sanitizedFilename,
                            },
                            {
                                extend: 'excelHtml5',
                                text: 'Excel',
                                filename: sanitizedFilename,
                            },
                            {
                                extend: 'pdfHtml5',
                                text: 'PDF',
                                filename: sanitizedFilename,
                                title: attachment.name,
                            },
                        ],
                    }}
                >
                    <thead>
                        <tr>
                            {columns.map((column: ExtendedAjaxDataColumn, index: number) => (
                                <th key={index}>{column.title}</th>
                            ))}
                        </tr>
                    </thead>
                </DataTable>
            </div>
        );
    }
    return null;
};

export default AiaDataTable;
