import { User, WebStorageStateStore } from 'oidc-client-ts';

function removeOidcParameters(url: URL) {
    url.searchParams.delete('code');
    url.searchParams.delete('scope');
    url.searchParams.delete('session_state');
    url.searchParams.delete('iss');
    url.searchParams.delete('state');
    url.searchParams.delete('loggedout');
    return url;
}

const oidcConfig = {
    accessTokenExpiringNotificationTimeInSeconds: 240,
    metadataUrl: process.env.REACT_APP_OPENIDCONNECT_METADATA_URL,
    authority: process.env.REACT_APP_OPENIDCONNECT_AUTHORITY ?? '',
    client_id: process.env.REACT_APP_OPENIDCONNECT_CLIENT_ID ?? '',
    redirect_uri: process.env.REACT_APP_OPENIDCONNECT_REDIRECT_URI ?? '',
    post_logout_redirect_uri: process.env.REACT_APP_OPENIDCONNECT_REDIRECT_URI,
    response_type: 'code',
    scope: `openid profile email roles ${process.env.REACT_APP_OPENIDCONNECT_API_SCOPE ?? ''} offline_access`,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    onSigninCallback: (_user: User | undefined): void => {
        const url = new URL(window.location.href);
        window.history.replaceState({}, document.title, removeOidcParameters(url).toString());
    },
};

const getOidcConfig = () => oidcConfig;

export const OidcAuthHelper = {
    getOidcConfig,
};
