import { useEffect, useRef } from 'react';
import { useAuth } from 'react-oidc-context';
import { useDispatch } from 'react-redux';
import { setActiveUserInfo, setAuthToken } from '../../redux/features/app/appSlice';
import { AuthHelper } from '../auth/AuthHelper';
import { useAppSelector } from '../../redux/app/hooks';

export const useAuthSetup = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    // keep a ref if we did autologin
    const didAutoLoginRef = useRef(false);
    const { embeddedMode } = useAppSelector((state) => state.app);

    // Add this outside the hook to maintain across renders
    let currentSignInPromise: Promise<void> | null = null;

    const handleSilentSignIn = async () => {
        // If there's already a sign-in in progress, wait for it
        if (currentSignInPromise) {
            console.log('Silent signin already in progress');
            return currentSignInPromise;
        }
        if (didAutoLoginRef.current) {
            console.log('Silent signin already did autologin');
            return;
        }
        didAutoLoginRef.current = true;
        console.log('Silent signin start');

        const url = new URL(window.location.href);
        const hostContextJson = url.searchParams.get('hostContext');
        // Create new promise and store it
        currentSignInPromise = (async () => {
            try {
                console.log('handleSilentSignIn start');

                const user = await Auth.signinSilent();
                console.log('Silent signin', user);
                if (!user) {
                    await Auth.signinRedirect({
                        state: hostContextJson ? btoa(hostContextJson) : undefined,
                    });
                }
            } catch (error) {
                console.error('Error during silent signin:', error);
                await Auth.signinRedirect({
                    state: hostContextJson ? btoa(hostContextJson) : undefined,
                });
            } finally {
                // Clear the promise when done
                currentSignInPromise = null;
            }
        })();

        return currentSignInPromise;
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (!Auth.isAuthenticated && !(window.location.pathname === '/' && urlParams.get('code'))) {
            const lastlogin = localStorage.getItem('oidcAutoLastLogin');
            console.log(
                'Auto login: isAuthenticated, pathname, lastlogin',
                Auth.isAuthenticated,
                window.location.pathname,
                lastlogin,
            );
            const shouldAutoLogin =
                embeddedMode || !lastlogin || new Date().getTime() - new Date(lastlogin).getTime() >= 30000;

            if (shouldAutoLogin) {
                console.log('Should auto login');
                localStorage.setItem('oidcAutoLastLogin', new Date().toISOString());
                void handleSilentSignIn();
            }
        } else {
            console.log('Not auto login', Auth.isAuthenticated, window.location);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Auth.isAuthenticated, window.location.href, embeddedMode]);

    useEffect(() => {
        dispatch(setAuthToken(Auth.user?.access_token ?? null));
        if (Auth.isAuthenticated && Auth.user) {
            dispatch(
                setActiveUserInfo({
                    id: Auth.user.profile.sub,
                    email: Auth.user.profile.email ?? '',
                    username: Auth.user.profile.name ?? '',
                    sessionId: AuthHelper.getSessionIdFromToken(Auth.user.access_token),
                }),
            );
        }
    }, [Auth, dispatch]);

    useEffect(() => {
        if (Auth.isAuthenticated) {
            AuthHelper.setLogoutFunction(async () => {
                try {
                    localStorage.removeItem('oidcAutoLastLogin');
                    await Auth.removeUser();
                    await Auth.clearStaleState();
                    const url = new URL(window.location.href);
                    url.searchParams.set('loggedout', 'true');
                    window.location.href = url.toString();
                } catch (error) {
                    console.error('Error during logout:', error);
                }
            });
            localStorage.removeItem('oidcAutoLastLogin');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Auth.isAuthenticated]);
};
