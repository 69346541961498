import { useEffect, useRef } from 'react';
import {
    Dialog,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Label,
    Radio,
    RadioGroup,
    Spinner,
    Textarea,
} from '@fluentui/react-components';

import { useTranslation } from 'react-i18next';
import { RootState } from '../../redux/app/store';
import {
    addAlert,
    setFeedbackIsOpen,
    setFeedbackIsSending,
    setFeedbackValue,
    setFeedbackContext,
    setFeedbackRating,
} from '../../redux/features/app/appSlice';
import { AlertType } from '../../libs/models/AlertType';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { useSelector } from 'react-redux';
import { IChatMessage } from '../../libs/models/ChatMessage';
import { PostEvent } from '../../embed-script/iframeWindow';
import { FlexDialogActions, PrimaryButton, SecondaryButton, SpinnerContainer } from './StyledCommonComponents';
import { makeStyles, shorthands } from '@fluentui/react-components';
import { useFeedback } from '../../libs/hooks/useFeedback';

const env = process.env.NODE_ENV;
const appName = process.env.REACT_APP_APPLICATIONNAME;

const useClasses = makeStyles({
    btn: {
        backgroundColor: '#FFF',
        cursor: 'pointer',
    },
    'btn-feedback': {
        right: '1rem',
        position: 'absolute',
        ...shorthands.margin('15px 5px'),
        ...shorthands.border('1px', 'solid', '#8A8A8A'),
        ...shorthands.borderRadius('5px'),
        ...shorthands.padding('5px', '15px'),
        width: '100px',
    },
});

export const Feedback = () => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const { isOpen, isSending, value, isPositive, context, rating, hideRating } = useSelector(
        (state: RootState) => state.app.feedbackForm,
    );
    const { email } = useSelector((state: RootState) => state.app.activeUserInfo) ?? {};
    const hostContext = useSelector((state: RootState) => state.hostContext.hostContext);

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const classes = useClasses();

    const { postFeedback } = useFeedback();

    useEffect(() => {
        function handlePostMessage(event: MessageEvent<PostEvent>) {
            const ev: PostEvent = event.data;
            if (ev.event === 'hashchange') {
                const data = ev.data as { hash: string; title: string | undefined };
                dispatch(setFeedbackContext({ url: data.hash, pageTitle: data.title }));
            }
        }

        window.addEventListener('message', handlePostMessage);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('message', handlePostMessage);
        };
    }, [dispatch]);

    useEffect(() => {
        if (isOpen && textareaRef.current) {
            textareaRef.current.focus();
        }
    }, [isOpen]);

    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);

    const handleSendFeedback = () => {
        if (isSending) return;

        dispatch(setFeedbackIsSending(true));

        const formatMessage = (message: IChatMessage) => {
            if (message.userId.toLowerCase() === 'bot') {
                return `bot> ${message.content}`;
            } else {
                return `user> ${message.content}`;
            }
        };

        // AIA-73: there is a eslint ts error because of wrongly defined Record on messages, as it can be null
        // fixing that error gives another 94 errors all over the codebase.
        /* eslint-disable @typescript-eslint/no-unnecessary-condition */
        const selectedMessages = conversations[selectedId]?.messages ?? [];
        const chatLog = selectedMessages.map(formatMessage).join('\n');
        const messageIndex = selectedMessages.findIndex((msg) => msg.id === context.messageId);
        const feedbackedMsg = (
            selectedMessages && messageIndex !== undefined && messageIndex > 0
                ? selectedMessages.slice(messageIndex - 1, messageIndex + 1)
                : []
        )
            .map(formatMessage)
            .join('\n');
        /* eslint-enable @typescript-eslint/no-unnecessary-condition */
        const sourceLinks = context.contentMetadata
            ?.filter((element) => element.orderOfAppearance > 0)
            .map((tuple) => tuple.url ?? '');

        postFeedback({
            text: value,
            history: chatLog,
            env,
            appName,
            isPositive,
            sourceLinks,
            chatId: context.chatId,
            messageId: context.messageId,
            feedbackedMsg,
            email,
            url: context.url,
            pageTitle: context.pageTitle,
            rating,
            hostId: hostContext.id,
            products: hostContext.products,
            country: hostContext.country,
        })
            .then((_) => {
                dispatch(setFeedbackIsOpen({ isOpen: false, hideRating: false }));
                dispatch(setFeedbackContext({}));
                dispatch(setFeedbackRating(null));
            })
            .catch((e: unknown) => {
                console.error(e);
                dispatch(addAlert({ message: t('feedback.errorSending'), type: AlertType.Error }));
            })
            .finally(() => {
                dispatch(setFeedbackIsSending(false));
            });
    };

    return (
        <Dialog
            modalType="modal"
            open={isOpen}
            onOpenChange={(_, data) => {
                dispatch(setFeedbackIsOpen({ isOpen: data.open, hideRating: false }));
            }}
        >
            <DialogTrigger disableButtonEnhancement>
                <button className={`${classes.btn} ${classes['btn-feedback']}`}>{t('feedback.triggerButton')}</button>
            </DialogTrigger>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>{t('feedback.dialog.title')}</DialogTitle>
                    <DialogContent style={{ whiteSpace: 'pre-wrap' }}>
                        <div dangerouslySetInnerHTML={{ __html: t('feedback.dialog.content') }} />
                        <Textarea
                            ref={textareaRef}
                            onChange={(_, data) => {
                                dispatch(setFeedbackValue(data.value));
                            }}
                            style={{ width: '100%', marginTop: '1em' }}
                            required
                            id={'feedback-text'}
                        />
                        {!hideRating && (
                            <>
                                <Label>{t('feedback.dialog.ratingLabel')}</Label>
                                <RadioGroup
                                    layout="horizontal"
                                    onChange={(_, data) => {
                                        dispatch(setFeedbackRating(data.value));
                                    }}
                                >
                                    {[1, 2, 3, 4, 5].map((value) => (
                                        <Radio key={value} value={value.toString()} label={value.toString()} />
                                    ))}
                                </RadioGroup>
                            </>
                        )}
                    </DialogContent>
                    <FlexDialogActions style={{ display: 'flex', flexWrap: 'nowrap' }}>
                        {isSending ? (
                            <SecondaryButton>
                                <SpinnerContainer>
                                    <Spinner />
                                </SpinnerContainer>
                            </SecondaryButton>
                        ) : (
                            <PrimaryButton onClick={handleSendFeedback}>
                                {t('feedback.dialog.submitButton')}
                            </PrimaryButton>
                        )}
                        <DialogTrigger disableButtonEnhancement>
                            <SecondaryButton>{t('feedback.dialog.cancelButton')}</SecondaryButton>
                        </DialogTrigger>
                    </FlexDialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
