import React from 'react';
import ReactDOM from 'react-dom/client';
import hasOwn from 'hasown';
import { AuthProvider } from 'react-oidc-context';
import { FluentProvider } from '@fluentui/react-components';
import { Provider as ReduxProvider } from 'react-redux';

import App from './App';
import MissingEnvVariablesError from './components/views/MissingEnvVariablesError';
import './index.css';
import { AuthHelper, AuthType } from './libs/auth/AuthHelper';
import { store } from './redux/app/store';
import { getMissingEnvVariables } from './checkEnv';
import { semanticKernelLightTheme } from './styles';
import * as tracking from './libs/tracking';
import { useFeatureFlags } from './components/FeatureFlagInitializer';
import { NoneAuthProvider } from './libs/auth/NoneAuthContext';
import { OidcAuthHelper } from './libs/auth/OidcAuthHelper';
import { useAuthSetup } from './libs/hooks/useAuthSetup';

// Polyfill for Object.hasOwn
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (!Object.hasOwn) {
    Object.hasOwn = function (obj, key) {
        return hasOwn(obj, key);
    };
}

tracking.init();

const AppWithThemeAndAuth: React.FC = () => {
    useAuthSetup();
    useFeatureFlags();

    return (
        <FluentProvider className="app-container" theme={semanticKernelLightTheme}>
            <App />
        </FluentProvider>
    );
};

const Root: React.FC = () => {
    const missingEnvVariables = getMissingEnvVariables();
    const validEnvFile = missingEnvVariables.length === 0;
    const shouldUseNone = validEnvFile && AuthHelper.getAuthType() === AuthType.None;

    if (!validEnvFile) {
        return <MissingEnvVariablesError missingVariables={missingEnvVariables} />;
    }

    return (
        <ReduxProvider store={store}>
            {shouldUseNone ? (
                <NoneAuthProvider>
                    <AppWithThemeAndAuth />
                </NoneAuthProvider>
            ) : (
                <AuthProvider {...OidcAuthHelper.getOidcConfig()}>
                    <AppWithThemeAndAuth />
                </AuthProvider>
            )}
        </ReduxProvider>
    );
};

let container: HTMLElement | null = null;
document.addEventListener('DOMContentLoaded', () => {
    if (!container) {
        container = document.getElementById('root');
        if (!container) {
            throw new Error('Could not find root element');
        }
        const root = ReactDOM.createRoot(container);
        root.render(
            <React.StrictMode>
                <Root />
            </React.StrictMode>,
        );
    }
});
