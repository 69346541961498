import { Button, makeStyles, Text, Title3, tokens, useId, Textarea } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useChat } from '../../libs/hooks/useChat';
import { Dismiss24Regular, OpenRegular } from '@fluentui/react-icons';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minWidth: '40%',
        margin: '0 auto',
        padding: tokens.spacingVerticalL,
    },
    ratingContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: tokens.spacingHorizontalM,
        marginBottom: tokens.spacingVerticalM,
    },
    buttonsContainer: {
        display: 'flex',
        gap: tokens.spacingHorizontalXS,
    },
    ratingButton: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        padding: 0,
        minWidth: 'unset',
    },
    trustButton: {
        width: '32px',
        height: '32px',
        borderRadius: '50%',
        padding: 0,
        minWidth: 'unset',
    },
    label: {
        color: tokens.colorNeutralForeground3,
        fontSize: tokens.fontSizeBase200,
        fontStyle: 'italic',
    },
    input: {
        width: '100%',
        marginBottom: tokens.spacingVerticalL,
    },
    submitButton: {
        float: 'right',
        backgroundColor: '#2D2C2C',
        color: 'white',
        '&:hover': {
            backgroundColor: '#1F1F1F',
        },
        borderLeft: '0px',
        height: '3.29rem',
    },
    feedbackContainer: {
        opacity: 0,
        transform: 'translateY(-10px)',
        transition: 'opacity 0.4s ease, transform 0.4s ease',
        '&.visible': {
            opacity: 1,
            transform: 'translateY(0)',
        },
        display: 'flex',
        width: '100%',
        marginBottom: tokens.spacingVerticalL,
        height: '4rem',
    },
    csatTextarea: {
        width: '100%',
        marginBottom: tokens.spacingVerticalM,
        '& textarea': {
            resize: 'vertical',
            minHeight: '80px',
        },
    },

    thankYouMessage: {},
    buttonContainer: {
        // display: 'flex',
        // gap: tokens.spacingHorizontalM,
        // width: '100%',
        // justifyContent: 'flex-end',
    },
    declineButton: {
        //marginTop: tokens.spacingVerticalL,
    },
    privacyLink: {
        display: 'inline-flex',
        alignItems: 'center',
        gap: '4px',
        textDecoration: 'none',
        color: tokens.colorNeutralForeground3,
        '&:hover': {
            color: tokens.colorNeutralForeground2,
        },
        fontStyle: 'normal',
    },
    closeButton: {
        position: 'absolute',
        top: '16px',
        right: '16px',
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        padding: '8px 12px',
        borderRadius: '20px',
        backgroundColor: tokens.colorNeutralBackground2,
        border: 'none',
        transition: 'all 0.2s ease',
        minWidth: '32px',
        height: '32px',
        overflow: 'hidden',

        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground3,
            width: 'auto',
            minWidth: '120px',
        },

        '& .buttonText': {
            maxWidth: 0,
            opacity: 0,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            transition: 'all 0.2s ease',
        },

        '&:hover .buttonText': {
            maxWidth: '100px',
            opacity: 1,
            marginLeft: '4px',
        },
    },
});

interface SatisfactionRatingProps {
    isOpen: boolean;
    onClose: () => void;
}

const SatisfactionRating: React.FC<SatisfactionRatingProps> = ({ isOpen, onClose }) => {
    const styles = useStyles();
    const { t } = useTranslation();
    const inputId = useId('feedback');
    const [selectedRating, setSelectedRating] = useState<number | null>(null);
    const [feedback, setFeedback] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isFeedbackVisible, setIsFeedbackVisible] = useState(false);
    const chat = useChat();

    const handleRatingSelect = async (rating: number) => {
        setSelectedRating(rating);
        window.parent.postMessage({ event: 'adjustCsatHeight', data: { height: '40.7rem' } }, '*');
        await chat.submitCsatRatings(rating, feedback);
        setTimeout(() => {
            setIsFeedbackVisible(true);
        }, 50);
    };

    const handleSubmitFeedback = async () => {
        if (selectedRating) {
            window.parent.postMessage({ event: 'adjustCsatHeight', data: { height: '40.7rem' } }, '*');
            await chat.submitCsatRatings(selectedRating, feedback);
            setSubmitted(true);
            setTimeout(() => {
                onClose();
            }, 1500);
        }
    };

    const handleDecline = async () => {
        await chat.declineCsat();
        onClose();
    };

    if (!isOpen) return null;

    if (submitted) {
        return (
            <div className={styles.root}>
                <Title3 block align="center" className={styles.thankYouMessage}>
                    <div className={styles.thankYouMessage}>{t('csat.thankYou')}</div>
                </Title3>
            </div>
        );
    }

    const hasSelectedRating = selectedRating !== null;
    let title = t('csat.satisfactionQuestion');
    if (hasSelectedRating) {
        if (selectedRating && selectedRating <= 2) {
            title = t('csat.thankYouLow');
        } else if (selectedRating && selectedRating <= 3) {
            title = t('csat.thankYouMedium');
        } else {
            title = t('csat.thankYouHigh');
        }
    }

    return (
        <div className={styles.root}>
            <Button
                appearance="subtle"
                className={styles.closeButton}
                onClick={() => {
                    void handleDecline();
                }}
                data-testid="csat-close"
            >
                <Dismiss24Regular />
                <span className="buttonText">{t('csat.dismiss')}</span>
            </Button>

            <Title3
                block
                align="center"
                style={{ marginBottom: tokens.spacingVerticalL, fontSize: tokens.fontSizeBase400, color: '#48237D' }}
            >
                {title}
            </Title3>

            {selectedRating && (
                <div className={`${styles.feedbackContainer}${isFeedbackVisible ? ' visible' : ''}`}>
                    <Textarea
                        id={inputId}
                        className={styles.csatTextarea}
                        value={feedback}
                        onChange={(e) => {
                            setFeedback(e.target.value);
                        }}
                        placeholder={t('csat.feedbackPlaceholder')}
                    />
                    <div className={styles.buttonContainer}>
                        <Button
                            className={styles.submitButton}
                            onClick={() => {
                                void handleSubmitFeedback();
                            }}
                            data-testid="csat-submit"
                        >
                            {t('csat.submit')}
                        </Button>
                    </div>
                </div>
            )}

            <div className={styles.ratingContainer}>
                <Text className={styles.label}>{t('csat.ratingLow')}</Text>
                <div className={styles.buttonsContainer}>
                    {[1, 2, 3, 4, 5].map((rating) => (
                        <Button
                            key={rating}
                            data-testid={`csat-star-${rating.toString()}`}
                            appearance={selectedRating === rating ? 'primary' : 'outline'}
                            className={styles.ratingButton}
                            onClick={() => {
                                void handleRatingSelect(rating);
                            }}
                        >
                            {rating}
                        </Button>
                    ))}
                </div>
                <Text className={styles.label}>{t('csat.ratingHigh')}</Text>
            </div>
            <Text className={styles.label}>
                <a
                    href={t('csat.privacyPolicyLink')}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.privacyLink}
                >
                    {t('csat.privacyPolicy')}
                    <OpenRegular />
                </a>
            </Text>
        </div>
    );
};

export default SatisfactionRating;
