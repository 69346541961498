import { URLSearchParams } from 'url';
import { HostContext } from '../../embed-script/HostContext';
import i18n from '../../i18n';
import { AlertType } from '../../libs/models/AlertType';
import { store } from '../../redux/app/store';
import { addAlert, setRateLimit } from '../../redux/features/app/appSlice';
import { AuthHelper } from '../auth/AuthHelper';

interface ServiceRequest {
    commandPath: string;
    method?: string;
    body?: unknown;
    query?: URLSearchParams;
    hostContext?: HostContext;
}

const noResponseBodyStatusCodes = [202, 204];

export const BackendServiceUrl = process.env.REACT_APP_BACKEND_URI ?? window.origin;

export class NotFoundError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'NotFoundError';
    }
}

export class BaseService {
    constructor(
        protected readonly accessToken: string,
        protected readonly serviceUrl: string = BackendServiceUrl,
    ) {}

    protected readonly getResponseAsync = async <T>(request: ServiceRequest): Promise<T> => {
        const { commandPath, method, body, query, hostContext } = request;

        const isFormData = body instanceof FormData;

        const headers = new Headers(
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            this.accessToken
                ? {
                      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                      Authorization: `Bearer ${this.accessToken}`,
                  }
                : undefined,
        );

        if (!isFormData) {
            headers.append('Content-Type', 'application/json');
        }

        if (hostContext) {
            headers.append('AiAHostContext', JSON.stringify(hostContext));
        }

        try {
            const requestUrl = new URL(commandPath, this.serviceUrl);
            if (query) {
                requestUrl.search = `?${query.toString()}`;
            }

            const response = await fetch(requestUrl, {
                method: method ?? 'GET',
                body: isFormData ? body : JSON.stringify(body),
                headers,
            });

            if (!response.ok) {
                const responseText = await response.text();
                const responseDetails = responseText.split('--->');
                const errorDetails =
                    responseDetails.length > 1
                        ? `${responseDetails[0].trim()} ---> ${responseDetails[1].trim()}`
                        : responseDetails[0];
                const errorMessage = `${response.status.toString()}: ${response.statusText}${errorDetails}`;

                switch (response.status) {
                    case 504:
                        throw Object.assign(new Error('The request timed out. Please try sending your message again.'));

                    case 401:
                        if (responseText === 'This token has been blocked.') {
                            await AuthHelper.logoutForBlockedToken();
                        }
                        throw Object.assign(new Error('You are not authorized to perform this action.'));

                    case 429:
                        const retryAfter = response.headers.get('Retry-After');
                        const resetTime = retryAfter ? Date.now() + parseInt(retryAfter, 10) * 1000 : undefined;

                        store.dispatch(setRateLimit({ isLimited: true, resetTime }));
                        store.dispatch(
                            addAlert({
                                message: i18n.t('alerts.rateLimitExceeded'),
                                type: AlertType.Warning,
                            }),
                        );
                        break;
                    case 404:
                        throw new NotFoundError(errorMessage);
                    default:
                        throw new Error(errorMessage);
                }
            }

            return (noResponseBodyStatusCodes.includes(response.status) ? {} : await response.json()) as T;
        } catch (e: any) {
            let additionalErrorMsg = '';
            if (e instanceof TypeError) {
                // fetch() will reject with a TypeError when a network error is encountered.
                additionalErrorMsg =
                    '\n\nPlease check that your backend is running and that it is accessible by the app';
            }
            throw Object.assign(new Error(`${e as string} ${additionalErrorMsg}`));
        }
    };
}
