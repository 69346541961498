import React from 'react';
import { ChartAttachment } from '../../libs/models/ChatMessage';
import { ChartComponent, useChart } from '../../libs/hooks/useChart';
import { useChartData } from '../../libs/hooks/useChartData';

interface AiaChartProps {
    attachment: ChartAttachment;
}

export const AiaChart: React.FC<AiaChartProps> = ({ attachment }) => {
    const Chart: ChartComponent | null = useChart();
    const processedData = useChartData(attachment);

    if (!Chart || !processedData) {
        return null;
    }

    return <Chart data={processedData.data} options={processedData.options} type={processedData.type} />;
};
