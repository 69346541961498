import * as React from 'react';
import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogContent,
    DialogActions,
    Button,
} from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';

const STORAGE_KEY = 'spcs-aia-welcome';

export const WelcomeDialog = () => {
    const [isOpen, setIsOpen] = React.useState(() => {
        try {
            return localStorage.getItem(STORAGE_KEY) !== 'true';
        } catch (e) {
            // Handle error or set a default state if localStorage is not accessible
            console.error('Error accessing localStorage:', e);
            return true; // Default to showing the dialog if we can't access localStorage
        }
    });
    const { t } = useTranslation();

    const handleClose = () => {
        // When the dialog is closed, save the state to localStorage
        try {
            localStorage.setItem(STORAGE_KEY, 'true');
        } catch (e) {
            console.error('Failed to save state to localStorage:', e);
        }
        setIsOpen(false);
    };

    return (
        <Dialog
            open={isOpen}
            onOpenChange={(_, data) => {
                if (!data.open) {
                    handleClose();
                }
            }}
            modalType="alert"
        >
            <DialogSurface>
                <DialogBody>
                    <DialogTitle data-testid="welcome-dialog-title">{t('welcomeDialog.title')}</DialogTitle>
                    <DialogContent>
                        <p>{t('welcomeDialog.text1')}</p>
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button data-testid="close-button" appearance="primary">
                                {t('welcomeDialog.confirm')}
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
