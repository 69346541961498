import { useEffect } from 'react';
import { useAppDispatch } from '../../redux/app/hooks';
import { setEmbeddedMode } from '../../redux/features/app/appSlice';

export const useEmbeddedMode = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        const embedded = params.get('embedded');

        if (embedded === 'true' || window.self !== window.top) {
            dispatch(setEmbeddedMode(true));
        }
    }, [dispatch]);
};
