import {
    Body1Strong,
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Divider,
    SelectTabEventHandler,
    Tab,
    TabList,
    TabValue,
    Tooltip,
    makeStyles,
    shorthands,
} from '@fluentui/react-components';
import { Info16Regular } from '@fluentui/react-icons';
import React, { useEffect } from 'react';
import { BotResponsePrompt, PromptSectionsNameMap } from '../../../libs/models/BotResponsePrompt';
import { IChatMessage } from '../../../libs/models/ChatMessage';
import { TokenUsageGraph } from '../../token-usage/TokenUsageGraph';
import { formatParagraphTextContent } from '../../utils/TextUtils';
import { useDialogClasses } from '../../../styles';
import { versionInfo } from '../../../version';

const useClasses = makeStyles({
    infoButton: {
        ...shorthands.padding(0),
        ...shorthands.margin(0),
        minWidth: 'auto',
        marginLeft: 'auto', // align to right
    },
});

interface IPromptDialogProps {
    message: IChatMessage;
}

export const PromptDialog: React.FC<IPromptDialogProps> = ({ message }) => {
    const classes = useClasses();
    const dialogClasses = useDialogClasses();

    const [selectedTab, setSelectedTab] = React.useState<TabValue>('unselected');
    const onTabSelect: SelectTabEventHandler = (_event, data) => {
        setSelectedTab(data.value);
    };

    let prompt: string | BotResponsePrompt;
    try {
        prompt = JSON.parse(message.prompt ?? '{}') as BotResponsePrompt;
    } catch (_) {
        prompt = message.prompt ?? '';
    }

    let promptDetails;
    if (typeof prompt === 'string') {
        promptDetails = formatParagraphTextContent(prompt);
    } else {
        promptDetails = Object.entries(prompt).map(([key, value]) => {
            if (
                key === 'chatMemories' &&
                value &&
                !(value as string).includes('User has also shared some document snippets:')
            ) {
                value = (value as string) + '\nNo relevant document memories.';
            }

            return value && key !== 'metaPromptTemplate' ? (
                <div className={dialogClasses.paragraphs} key={`prompt-details-${key}`}>
                    <Body1Strong>{PromptSectionsNameMap[key]}</Body1Strong>
                    {formatParagraphTextContent(value as string)}
                </div>
            ) : null;
        });
    }

    const debugCommands = [
        {
            command: 'debug:plugins',
            description: 'Lists active plugins with their functions, parameters, and descriptions',
        },
        { command: 'debug:whoami', description: 'Displays current user and session information' },
        { command: 'debug:flags', description: 'Shows the status of system feature flags' },
        { command: 'debug:version', description: 'Shows version information for frontend and backend' },
        { command: 'debug:help', description: 'Lists all available debug commands' },
    ];

    const toolUsageDetails: Array<{ name: string; arguments: string }> = [];
    if (message.toolCalls && message.toolCalls.length > 0) {
        message.toolCalls.forEach((toolCall) => {
            toolUsageDetails.push({
                name: toolCall.functionName,
                arguments: toolCall.functionArguments,
            });
        });
    }

    const sqlQueries = message.datasets?.filter((dataset) => dataset.sqlQuery);

    useEffect(() => {
        if (selectedTab === 'unselected') {
            if (toolUsageDetails.length > 0) {
                setSelectedTab('toolUsage');
            } else if (message.prompt) {
                setSelectedTab('formatted');
            } else {
                setSelectedTab('debugCommands');
            }
        }
    }, [message.chatHistory, message.prompt, toolUsageDetails.length, selectedTab]);

    return (
        <Dialog>
            <DialogTrigger disableButtonEnhancement>
                <Tooltip content={'Show prompt'} relationship="label">
                    <Button className={classes.infoButton} icon={<Info16Regular />} appearance="transparent" />
                </Tooltip>
            </DialogTrigger>
            <DialogSurface className={dialogClasses.surface}>
                <DialogBody
                    style={{
                        height: !message.prompt ? 'fit-content' : '825px',
                    }}
                >
                    <DialogTitle>Debug</DialogTitle>
                    <DialogContent className={dialogClasses.content}>
                        <TokenUsageGraph promptView tokenUsage={message.tokenUsage ?? {}} />
                        <TabList selectedValue={selectedTab} onTabSelect={onTabSelect}>
                            {message.prompt && (
                                <Tab data-testid="formatted" id="formatted" value="formatted">
                                    Formatted
                                </Tab>
                            )}
                            {message.prompt && typeof prompt !== 'string' && (
                                <Tab data-testid="rawContent" id="rawContent" value="rawContent">
                                    Raw Content
                                </Tab>
                            )}
                            {toolUsageDetails.length > 0 && (
                                <Tab data-testid="toolUsage" id="toolUsage" value="toolUsage">
                                    Tool usage
                                </Tab>
                            )}
                            {sqlQueries && sqlQueries.length > 0 && (
                                <Tab data-testid="sqlQueries" id="sqlQueries" value="sqlQueries">
                                    SQL Queries
                                </Tab>
                            )}
                            <Tab data-testid="debugCommands" id="debugCommands" value="debugCommands">
                                Debug Commands
                            </Tab>
                        </TabList>

                        <div
                            className={
                                message.prompt && typeof prompt !== 'string' ? dialogClasses.innerContent : undefined
                            }
                        >
                            {selectedTab === 'formatted' && promptDetails}
                            {selectedTab === 'rawContent' &&
                                (prompt as BotResponsePrompt).metaPromptTemplate.map((contextMessage, index) => {
                                    return (
                                        <div key={`context-message-${index.toString()}`}>
                                            <p>{`Role: ${contextMessage.Role.Label}`}</p>
                                            {contextMessage.Items.map((c) =>
                                                formatParagraphTextContent(`Content: ${c.Text}`),
                                            )}
                                            <Divider />
                                        </div>
                                    );
                                })}
                            {selectedTab === 'toolUsage' && (
                                <div>
                                    <h3>Tool Usage Details</h3>
                                    {toolUsageDetails.length > 0 ? (
                                        toolUsageDetails.map((detail, index) => (
                                            <div key={`tool-usage-${index.toString()}`}>
                                                <p>
                                                    <strong>Name:</strong> {detail.name}
                                                </p>
                                                <p>
                                                    <strong>Arguments:</strong> {detail.arguments}
                                                </p>
                                                <Divider />
                                            </div>
                                        ))
                                    ) : (
                                        <p>No tool usage details available.</p>
                                    )}
                                </div>
                            )}
                            {selectedTab === 'sqlQueries' && (
                                <div>
                                    <h3>SQL Queries</h3>
                                    {sqlQueries?.map((dataset, index) => (
                                        <div key={`sql-query-${index.toString()}`}>
                                            <strong>Query:</strong>
                                            <p>
                                                <code>{dataset.sqlQuery}</code>
                                            </p>
                                            <Divider />
                                        </div>
                                    ))}
                                </div>
                            )}
                            {selectedTab === 'debugCommands' && (
                                <div>
                                    <h3>Available Debug Commands</h3>
                                    <ul>
                                        {debugCommands.map((cmd, index) => (
                                            <li key={index}>
                                                <strong>{cmd.command}</strong>: {cmd.description}
                                            </li>
                                        ))}
                                    </ul>
                                    <h3>Frontend Version Information</h3>
                                    <ul>
                                        <li>
                                            <strong>Version:</strong> {versionInfo.version}
                                        </li>
                                        <li>
                                            <strong>Build Time:</strong> {versionInfo.buildTime}
                                        </li>
                                        <li>
                                            <strong>Commit SHA:</strong> {versionInfo.commitSha}
                                        </li>
                                        <li>
                                            <strong>Commit Time:</strong> {versionInfo.commitTime}
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    </DialogContent>
                    <DialogActions position="start" className={dialogClasses.footer}>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">Close</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
