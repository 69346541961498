import React from 'react';
import { makeStyles, shorthands, tokens, Button } from '@fluentui/react-components';
import { Attachment, IChatMessage } from '../../libs/models/ChatMessage';
import { AiaChart } from '../utils/AiaChart';
import AiaDataTable from '../utils/AiaDataTable';
import { useAttachment } from '../../contexts/AttachmentContext';
import CalculationGuideButton from '../utils/CalculationGuideButton';

const useClasses = makeStyles({
    container: {
        backgroundColor: '#f1eff6',
        ...shorthands.border('1px', 'solid', '#8049CC'),
        borderRadius: '15px',
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalL),
        boxShadow: 'rgb(0 0 0 / 10%) 0 0.2rem 0.4rem -0.075rem',
        margin: tokens.spacingVerticalL,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '80vh',
        overflow: 'hidden',
    },
    attachment: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minHeight: 0,
        position: 'relative',
        overflow: 'auto',
    },
    chartContainer: {
        flex: 1,
        minHeight: 0,
        width: '100%',
        position: 'relative',
    },
    closeButton: {
        position: 'relative',
        top: 'auto',
        right: 'auto',
        background: 'transparent',
        border: 'lightgray 1px solid',
        cursor: 'pointer',
        fontSize: '1rem',
        padding: '0.2rem',
        minWidth: 'min-content',
        height: 'min-content',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        lineHeight: 1,
    },
    headerSection: {
        display: 'flex',
        width: '100%',
        alignItems: 'flex-start',
        gap: '0.5rem',
        marginBottom: '0.5rem',
    },
    guideContainer: {
        flex: 1,
    },
});

interface AttachmentPanelProps {
    attachment: Attachment | null;
    message: IChatMessage | 'closed' | null;
}

export const AttachmentPanel: React.FC<AttachmentPanelProps> = ({ attachment, message }) => {
    const classes = useClasses();
    const { setActiveAttachment } = useAttachment();

    const handleClose = () => {
        setActiveAttachment(null, 'closed');
    };

    if (message === 'closed') {
        return <div className={classes.container}>Attachment panel is closed</div>;
    }

    if (!attachment) {
        return <div className={classes.container}>No attachment to display</div>;
    }

    const renderAttachment = () => {
        switch (attachment.attachmentType) {
            case 'table':
                return <AiaDataTable attachment={attachment} />;
            case 'chart':
                return (
                    <div className={classes.chartContainer}>
                        <AiaChart attachment={attachment} />
                    </div>
                );
            default:
                return <div className={classes.attachment}>Unsupported attachment type</div>;
        }
    };

    return (
        <div className={classes.container}>
            <div className={classes.headerSection}>
                <div className={classes.guideContainer}>
                    <CalculationGuideButton attachment={attachment} />
                </div>
                <Button className={classes.closeButton} onClick={handleClose}>
                    <span aria-label="Close">&times;</span>
                </Button>
            </div>
            {renderAttachment()}
        </div>
    );
};
