import React, { useCallback, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { IChatMessage } from '../../../libs/models/ChatMessage';
import { formatChatMessage } from '../../utils/ChatMessageUtils';
import CustomLink from '../../utils/CustomLink';
import { ChatHistorySourceLinks } from './ChatHistorySourceLinks';

interface ChatHistoryTextContentProps {
    message: IChatMessage;
    isLastMessage: boolean;
    isChatResponseComplete: boolean;
    isUser: boolean;
    v2ExperimentalFeatures: boolean;
}

export const ChatHistoryTextContent: React.FC<ChatHistoryTextContentProps> = React.memo(
    ({ message, isLastMessage, isChatResponseComplete, isUser, v2ExperimentalFeatures }) => {
        const content = useMemo(() => formatChatMessage(message), [message]);

        const showLinks = useMemo(() => {
            return (
                (isChatResponseComplete || !isLastMessage) &&
                message.contentMetadataArray?.contentMetadata.filter((x) => x.orderOfAppearance > 0).pop()
            );
        }, [isChatResponseComplete, isLastMessage, message.contentMetadataArray]);

        const renderContent = useCallback(() => {
            if (isUser) {
                return <div>{content}</div>;
            }

            return (
                <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    remarkPlugins={[remarkGfm]}
                    components={{
                        a: (props) => <CustomLink {...props} message={message} isLastMessage={isLastMessage} />,
                    }}
                >
                    {content}
                </ReactMarkdown>
            );
        }, [isUser, content, message, isLastMessage]);

        return (
            <>
                {renderContent()}
                {message.graphData && v2ExperimentalFeatures && (
                    <div style={{ maxHeight: '600px' }}>{/* Render graph data here */}</div>
                )}
                {showLinks && (
                    <ChatHistorySourceLinks contentMetadata={message.contentMetadataArray?.contentMetadata} />
                )}
            </>
        );
    },
);

ChatHistoryTextContent.displayName = 'ChatHistoryTextContent';

export default ChatHistoryTextContent;
